import { gql } from '@apollo/client';

export const GET_CAMPAIGN_METRICS = gql`
  query GetCampaignMetrics($campaignId: String!) {
    getCampaignMetrics(campaignId: $campaignId) 
  }
`;

export const GET_CAMPAIGN_USER_ASSET_METRICS = gql`
  query GetCampaignUserAssetMetrics($campaignId: String! $assetId: String!) {
    getCampaignUserAssetMetrics(campaignId: $campaignId, assetId: $assetId) 
  }
`;

export const GET_CAMPAIGN_USER_REWARD_METRICS = gql`
  query GetCampaignUserRewardMetrics($campaignId: String! $rewardId: String!) {
    getCampaignUserRewardMetrics(campaignId: $campaignId, rewardId: $rewardId) 
  }
`;

export const GET_CAMPAIGN_DETAILS = gql`
  query GetCampaignDetails($campaignId: String!) {
    getCampaignDetails(campaignId: $campaignId) 
  }
`;

export const LOGIN_ADVERTISER_CAMPAIGN_METRICS = gql`
  mutation LoginCampaignMetricsViewPage($campaignId: String! $campaignAccessKey: String!) {
    loginCampaignMetricsViewPage(campaignId: $campaignId, campaignAccessKey:$campaignAccessKey) 
  }
`;

export const GET_ADVERTISER_TOKEN_CAMPAIGN_METRICS = gql`
  query GetAdvertiserTokenCampaignMetricsViewPage($refresh: String!) {
    getAdvertiserTokenCampaignMetricsViewPage(refresh: $refresh) 
  }
`;