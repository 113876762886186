import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink, fromPromise } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from 'apollo-link-error';
import {split, HttpLink,from} from '@apollo/client';
import {getMainDefinition} from '@apollo/client/utilities';
import {GET_ADVERTISER_TOKEN_CAMPAIGN_METRICS} from './Graphql/Query';

let isRefreshing = false;
let pendingRequests = [];

// console.log = function(){};
export const setIsRefreshing = (value) => {
  isRefreshing = value
}

const addPendingRequest = (pendingRequest) => {
  pendingRequests.push(pendingRequest)
}

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback())
  pendingRequests = [];
}


const getNewToken = async () => {
  
    let localRefreshToken = localStorage.getItem('refresh');
  
    if(localRefreshToken!= undefined && localRefreshToken!= null && localRefreshToken!= '') {

      console.log('New Token Generating.....');
      let result  = await client.query({query:GET_ADVERTISER_TOKEN_CAMPAIGN_METRICS, variables:{refresh:localRefreshToken},fetchPolicy:"network-only"})
      
      console.log('result in getNewToken')
      console.log(result)

      if(result.data.getAdvertiserTokenCampaignMetricsViewPage.status == 'SUCCESS'){
        localStorage.setItem('token',result.data.getAdvertiserTokenCampaignMetricsViewPage.response.token)
        return(result.data.getAdvertiserTokenCampaignMetricsViewPage.response.token);
      }
      else{
          return null;
      }

    }

    else{
      return('REFRESH_TOKEN_NOT_PRESENT')
    }
  
    
  
}


const httpLink = createHttpLink({
  uri: 'https://api.gamespaces.xyz/graphql'  //'https://dev.api.gamespaces.xyz/graphql', // Replace with your GraphQL server endpoint
});

// Create an authentication link using apollo-link-context
const authLink = setContext((_, { headers }) => {
  // Retrieve your authentication token from where you store it (localStorage, cookies, etc.)
  const localToken = localStorage.getItem('token');
  console.log('localToken',localToken);
  const token = 'Bearer '+localToken;// Replace with your token retrieval logic

  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : '', // Include the token in the headers if available
    },
  };
});

const errorLink = onError( ({ graphQLErrors, networkError, operation, forward }) => {
  console.log('In Error Link')
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      if(err.message != null && err.message!= undefined){
        if(err.message == "UNAUTHORISED")
        {
          console.log('isRefreshing')
          console.log(isRefreshing)
          if (!isRefreshing) {
            setIsRefreshing(true);
            console.log('Token Expired');
            const oldHeaders = operation.getContext().headers;
            return fromPromise(
              getNewToken().then((generatedToken)=>{


                if(generatedToken == 'REFRESH_TOKEN_NOT_PRESENT') {
                  setIsRefreshing(false);
                }

                else if(generatedToken!= undefined && generatedToken !=null && generatedToken != '') {

                  resolvePendingRequests()
                  setIsRefreshing(false)
                  console.log('Generated Token....');
                  console.log(generatedToken)

                  operation.setContext({
                    headers: {
                      ...oldHeaders,
                      Authorization: generatedToken ? `Bearer ${generatedToken}` : "",
                    },
                  });

                }

                console.log('operation')
                console.log(operation);

                return forward(operation)
              })

            ).flatMap(()=> {
              resolvePendingRequests()
              setIsRefreshing(false)
              return forward(operation)
            })
          }

          else {
            return fromPromise(
              new Promise((resolve) => {
                addPendingRequest(() => resolve())
              }),
            ).flatMap(() => {
              return forward(operation)
            })
          }

        }
        else{
          console.log('Error')
          console.log(err)
        }
      }
    }
  }
  else if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
  else{
    console.log('Something Else is Wrong');
  }
});

const client = new ApolloClient({
  link: from([errorLink,authLink,httpLink]),
  cache: new InMemoryCache(),
});

export const globalApolloClient = client;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
   <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
