import React, { useEffect, useRef, useState } from "react";
import './CampaignMetricsView.css'
import { globalApolloClient } from "../../index";
import reloadWhiteIcon from '../../asset/ReloadWhiteIcon.png';
import reloadBlackIcon from '../../asset/ReloadBlackIcon.png';
import { GET_CAMPAIGN_METRICS } from '../../Graphql/Query';
import {GET_CAMPAIGN_USER_ASSET_METRICS} from '../../Graphql/Query';
import {GET_CAMPAIGN_USER_REWARD_METRICS} from '../../Graphql/Query';
import {GET_CAMPAIGN_DETAILS} from '../../Graphql/Query';


let storedCampaignId = localStorage.getItem('campaignId');

let currentSelectedReward = {
    rewardDescription:null,
    assetNameInCampaign:null,
    unlockInteractionCount:null,
    rewardKey:null
};

function addDaysAndFormat(inputDateString, numberOfDays) {
    const inputDate = new Date(inputDateString);
    const outputDate = new Date(inputDate.getTime() + numberOfDays * 24 * 60 * 60 * 1000);
  
    const day = outputDate.getDate();
    const month = outputDate.toLocaleString('default', { month: 'long' });
  
    let suffix = 'th';
    if (day === 1 || day === 21 || day === 31) {
      suffix = 'st';
    } else if (day === 2 || day === 22) {
      suffix = 'nd';
    } else if (day === 3 || day === 23) {
      suffix = 'rd';
    }
  
    const formattedOutput = `${day}${suffix} ${month}`;
  
    return formattedOutput;
}



function CampaignMetricsView(){

  const [metricsFilter, setMetricsFilter] = useState('OVERALL');
  const [campaignDetails, setCampaignDetails] = useState(undefined);
  const [campaignMetrics, setCampaignMetrics] = useState(undefined);
  const [reloadButtonHighlight, setReloadButtonHighlight] = useState(false);
  const [campaignAssetMetrics, setCampaignAssetMetrics] = useState(undefined);
  const [campaignUserAssetMetrics, setCampaignUserAssetMetrics] = useState(undefined);
  const [campaignRewardMetrics, setCampaignRewardMetrics] = useState(undefined);
  const [campaignUserRewardsMetrics, setCampaignUserRewardsMetrics] = useState(undefined);

    async function fetchCampaignDetails(campaignId){
        const result = await globalApolloClient.query({
            query: GET_CAMPAIGN_DETAILS,
            variables: { campaignId },
            fetchPolicy:"network-only"
        });

        console.log('result.data.getCampaignMetrics.response.campaignMetrics', result.data.getCampaignDetails.response)
        return result.data.getCampaignDetails.response;
    }

    async function fetchCampaignMetricsQuery(campaignId){
        const result = await globalApolloClient.query({
            query: GET_CAMPAIGN_METRICS,
            variables: { campaignId },
            fetchPolicy:"network-only"
        });

        // console.log('result.data.getCampaignMetrics.response.campaignMetrics', result.data.getCampaignMetrics.response.campaignMetrics)
        return result.data.getCampaignMetrics.response;
    }

    async function fetchCampaignUserAssetMetrics(campaignId,assetId){
        const result = await globalApolloClient.query({
            query: GET_CAMPAIGN_USER_ASSET_METRICS,
            variables: { campaignId,assetId },
            fetchPolicy:"network-only"
          });
    

        console.log('fetchCampaignUserAssetMetrics', result.data.getCampaignUserAssetMetrics.response)
        setCampaignUserAssetMetrics(result.data.getCampaignUserAssetMetrics.response);
    }

    async function fetchCampaignUserRewardMetrics(campaignId,rewardId){
        const result = await globalApolloClient.query({
            query: GET_CAMPAIGN_USER_REWARD_METRICS,
            variables: { campaignId,rewardId },
            fetchPolicy:"network-only"
          });
    
        console.log('result', result.data.getCampaignUserRewardMetrics.response);
        setCampaignUserRewardsMetrics(result.data.getCampaignUserRewardMetrics.response);
    }

    useEffect(()=>{
        const fetchData = async () => {
            let campDet = await fetchCampaignDetails(storedCampaignId);
            let campMet = await fetchCampaignMetricsQuery(storedCampaignId);
            setCampaignDetails(campDet);
            setCampaignMetrics(campMet.campaignMetrics);
            setCampaignAssetMetrics(campMet.campaignAssets);
            setCampaignRewardMetrics(campMet.campaignRewards);
        };
    
        fetchData(); // Call the async function directly
    
        // Return a cleanup function (if necessary)
        return () => {
            // Perform cleanup actions if needed
        };
    },[])
  

  const [viewUserMetricsList, setUserMetricsList] = useState(false);
  const [viewUserRewardsList, setUserRewardsList] = useState(false);

  const [linkButtonHover,setLinkButtonHover] = useState(false);

  const handleMouseEnterLinkButton = () => {
    setLinkButtonHover(true);
  };
  const handleMouseLeaveLinkButton = () => {
    setLinkButtonHover(false);
  };

    function RewardMetricsList({rewardDataList}) {
        let items = rewardDataList

        return (
            <div className="asset-item-scroll-container">
                {items.map((item, index) => (
                    <RewardMetricItem key={index} item={item} index={index} />
                ))}
            </div>
        );
    }

    function RewardMetricItem({ item, index }) {
        const [userRewardsButtonHover, setUserRewardsButtonHover] = useState(false);

        const handleMouseEnterUserRewardsButton = () => {
            setUserRewardsButtonHover(true);
        };

        const handleMouseLeaveUserRewardsButton = () => {
            setUserRewardsButtonHover(false);
        };

        return (
            <div
                key={index}
                className={`asset-metric-list-item`}
                style={{flexDirection:'column'}}
            >
                <div style={{ display: 'flex', flexDirection: 'row', width: '90%', height: '250px', alignSelf: 'center', justifyContent: 'space-between'}}>
                    
                    <div style={{display:'flex', width:"100%", height:'100%',flexDirection:'row', justifyContent:'center', alignSelf:'center'}}>
                            
                        <div style={{display:"flex", flexDirection:'column', width:"70%", height:"70%", justifyContent: 'center', alignSelf:"center"}}>
                            
                            <div style={{ display: 'flex', flexDirection: 'row', width: '80%', height:"30%", alignSelf: 'center',  justifyContent: 'center' ,backgroundColor:'#DAE011', borderRadius:"20px"}}>
                                <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#1a1a1a', alignSelf: "center" }}>{item.rewardDescription}</span>
                            </div>
                            
                            <div style={{ display: 'flex', flexDirection: 'row', width: '80%', height:"30%", alignSelf: 'center',  justifyContent: 'center' ,backgroundColor:'#DAE011', borderRadius:"20px", marginTop:'10px'}}>
                                <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#1a1a1a', alignSelf: "center" }}>linked to {item.asset.campaigns[0].assetNameInCampaign} asset</span>
                            </div>

                            <button style={{ display: 'flex', flexDirection: 'row', width: '80%', height:"30%", alignSelf: 'center',  justifyContent: 'center' ,backgroundColor:userRewardsButtonHover?'#dae011':'#0d0d0d', borderRadius:"20px", marginTop:'10px', borderColor:'transparent', cursor:'pointer'}}
                            onMouseEnter={handleMouseEnterUserRewardsButton}
                            onMouseLeave={handleMouseLeaveUserRewardsButton}
                            onClick={async ()=>{
                                currentSelectedReward.rewardDescription = item.rewardDescription;
                                currentSelectedReward.assetNameInCampaign = item.asset.campaigns[0].assetNameInCampaign;
                                currentSelectedReward.unlockInteractionCount = item.unlockInteractionCount;
                                console.log('item',item)
                                if(item.uniqueRewardKeys == false){
                                    currentSelectedReward.rewardKey = item.rewardKey;
                                }
                                await fetchCampaignUserRewardMetrics(storedCampaignId,item.rewardId)
                                setUserRewardsList(true);
                            }}>
                                <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: userRewardsButtonHover?'#0d0d0d':'white', alignSelf: "center" }}>view individual user metrics</span>
                            </button>
                        </div>

                        <div style={{display:"flex", flexDirection:"column", width:'30%', height:"100%", alignSelf:"center", justifyContent: 'center', }}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', marginBottom: '20px', marginTop:"20px" }}>
                                <span style={{ display: 'flex', fontSize: '18px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center" }}>Reward Claims Left</span>
                                <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center", marginTop:"10px" }}>{item.rewardUnClaimedCount}</span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', marginBottom: '20px'}}>
                                <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center" }}>Max Claimable Count</span>
                                <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center", marginTop:"10px" }}>{item.totalRewardClaimableCount}</span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', marginBottom: '20px' }}>
                                <span style={{ display: 'flex', fontSize: '18px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center" }}>Reward Unlock Interaction Count </span>
                                <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center", marginTop:"10px" }}>{item.unlockInteractionCount}</span>
                            </div>
                        </div>

                    </div>
                </div>
            
            </div>
        );
    }

    function UserRewardList({userRewardsData}) {
   
        console.log('userRewardsData',userRewardsData)

        const items = userRewardsData.userRewards
    
        console.log('items',items)

        return (
            <div className="asset-item-scroll-container">
                {items.map((item, index) => (
                    <UserRewardListItem key={index} item={item} index={index} />
                ))}
            </div>
        );
    }
    
    function UserRewardListItem({ item, index }) {
        const [userMetricsButtonHover, setUserMetricsButtonHover] = useState(false);
    
        const handleMouseEnterUserMetricsButton = () => {
            setUserMetricsButtonHover(true);
        };
    
        const handleMouseLeaveUserMetricsButton = () => {
            setUserMetricsButtonHover(false);
        };

        console.log('UserRewardListItem',item)
    
        return (
            <div
                key={index}
                className={`asset-metric-list-item`}
                style={{flexDirection:'column'}}
            >     
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '80px', alignSelf: 'center', justifyContent: 'center', marginTop:"20px"}}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%', justifyContent: 'center', marginRight: '20px'}}>
                        <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center" }}>User-Id</span>
                        <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center", marginTop:"10px" }}>{item.user.userId}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%', justifyContent: 'center', marginLeft: '20px' }}>
                        <span style={{ display: 'flex', fontSize: '18px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center" }}>User-Mail</span>
                        <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center", marginTop:"10px" }}>{item.user.emailId}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%', justifyContent: 'center', marginLeft: '20px' }}>
                        {(item.isClaimed )?
                        <div style={{ display: 'flex', flexDirection: 'row', width: '80%', height:"50%", alignSelf: 'center',  justifyContent: 'center' ,backgroundColor:'#DAE011', borderRadius:"20px"}}>
                            <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#1a1a1a', alignSelf: "center" }}>claimed</span>
                        </div>:
                        <div style={{ display: 'flex', flexDirection: 'row', width: '80%', height:"50%", alignSelf: 'center',  justifyContent: 'center' ,backgroundColor:'#0d0d0d', borderRadius:"20px"}}>
                            <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: 'white', alignSelf: "center" }}>not yet claimed</span>
                        </div>}
                        {(item.isUnlocked)?
                        <div style={{ display: 'flex', flexDirection: 'row', width: '80%', height:"50%", alignSelf: 'center',  justifyContent: 'center' ,backgroundColor:'#DAE011', borderRadius:"20px", marginTop:'10px'}}>
                            <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#1a1a1a', alignSelf: "center" }}>unlocked</span>
                        </div>:null}
                    </div>

                </div>         
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '80px', alignSelf: 'center', justifyContent: 'space-between', marginTop:"15px"}}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '25%', justifyContent: 'center', marginLeft: '20px'}}>
                        <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center" }}>Linked Asset</span>
                        <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center", marginTop:"10px" }}>{currentSelectedReward.assetNameInCampaign}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '40%', justifyContent: 'center', marginRight: '20px' }}>
                        <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center" }}>Reward Description</span>
                        <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center", marginTop:"10px" }}>{currentSelectedReward.rewardDescription}</span>
                    </div>
                    {(item.uniqueRewardKeyClaimed != null)?
                    <div style={{ display: 'flex', flexDirection: 'column', width: '25%', justifyContent: 'center', marginRight: '20px' }}>
                        <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center" }}>Reward Key</span>
                        <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center", marginTop:"10px" }}>{item.uniqueRewardKeyClaimed.rewardKey}</span>
                    </div>:
                    <div style={{ display: 'flex', flexDirection: 'column', width: '25%', justifyContent: 'center', marginRight: '20px' }}>
                        <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center" }}>Reward Key</span>
                        <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center", marginTop:"10px" }}>{currentSelectedReward.rewardKey}</span>
                    </div>}
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '80px', alignSelf: 'center', justifyContent: 'center', marginTop:"10px",paddingBottom:'20px',}}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%', justifyContent: 'center', marginRight: '20px' }}>
                        <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center" }}>Unlock Count</span>
                        <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center", marginTop:"10px" }}>{currentSelectedReward.unlockInteractionCount}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%', justifyContent: 'center', marginLeft: '20px' }}>
                        <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center" }}>Interaction Count</span>
                        <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center", marginTop:"10px" }}>{item.user.userAssetInteraction[0].interactionCount}</span>
                    </div>
                </div>

            </div>
        );
    }

    function AssetMetricsList({assetMetricsData}) {

    let items = assetMetricsData;
    console.log('assetMetricsData',assetMetricsData)

    return (
        <div className="asset-item-scroll-container">
            {items.map((item, index) => (
                <AssetMetricItem key={index} item={item} index={index} />
            ))}
        </div>
    );
    }

    function AssetMetricItem({ item, index}) {
        const [userMetricsButtonHover, setUserMetricsButtonHover] = useState(false);

        const handleMouseEnterUserMetricsButton = () => {
            setUserMetricsButtonHover(true);
        };

        const handleMouseLeaveUserMetricsButton = () => {
            setUserMetricsButtonHover(false);
        };



        return (
            <div
                key={index}
                className={`asset-metric-list-item`}
                style={{flexDirection:'column'}}
            >
                <div style={{ display: 'flex', flexDirection: 'row', width: '90%', height: '150px', alignSelf: 'center', justifyContent: 'space-between', paddingTop:'20px'}}>
                    
                    <div style={{display:'flex', width:"100%", height:'100%',flexDirection:'row'}}>
                        
                        <div style={{display:"flex", flexDirection:'column', width:'50%',height:"100%", justifyContent: 'center'}}>
                        
                            <img src={item.assetImageUrl} style={{height:"100%",  alignSelf:'center', borderRadius:'20px'}}></img>

                        </div>
                        
                        <div style={{display:"flex", flexDirection:'column', width:"50%", justifyContent: 'center'}}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '80%', height:"30%", alignSelf: 'center',  justifyContent: 'center' ,backgroundColor:'#DAE011', borderRadius:"20px", marginTop:'10px'}}>
                                <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#1a1a1a', alignSelf: "center" }}>{item.assetNameInCampaign}</span>
                            </div>
                            
                            <div style={{display:'flex', flexDircetion:"row", width:'100%',  height:"30%", justifyContent:'center', marginTop:'10px' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '40%', height:"100%", alignSelf: 'center', justifyContent: 'center' ,backgroundColor:'#DAE011', borderRadius:"20px",marginRight:"5px" }}>
                                    <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#1a1a1a', alignSelf: "center" }}>{(item.asset.assetType).toString().toLowerCase()}</span>
                                </div>
                                {(item.isRewardable)?
                                <div style={{ display: 'flex', flexDirection: 'row', width: '40%', height:"100%", alignSelf: 'center', justifyContent: 'center' ,backgroundColor:'#DAE011', borderRadius:"20px",marginLeft:"5px" }}>
                                    <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#1a1a1a', alignSelf: "center" }}>rewarded</span>
                                </div>:
                                <div style={{ display: 'flex', flexDirection: 'row', width: '40%', height:"100%", alignSelf: 'center', justifyContent: 'center' ,backgroundColor:'#DAE011', borderRadius:"20px",marginLeft:"5px" }}>
                                    <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#1a1a1a', alignSelf: "center" }}>no reward</span>
                                </div>}
                            </div>

                            <button style={{ display: 'flex', flexDirection: 'row', width: '80%', height:"30%", alignSelf: 'center',  justifyContent: 'center' ,backgroundColor:userMetricsButtonHover?'#dae011':'#0d0d0d', borderRadius:"20px", marginTop:'10px', borderColor:'transparent', cursor:'pointer'}}
                            onMouseEnter={handleMouseEnterUserMetricsButton}
                            onMouseLeave={handleMouseLeaveUserMetricsButton}
                            onClick={async ()=>{
                                await fetchCampaignUserAssetMetrics(storedCampaignId,item.assetId)
                                setUserMetricsList(true);
                            }}>
                                <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: userMetricsButtonHover?'#0d0d0d':'white', alignSelf: "center" }}>view individual user metrics</span>
                            </button>
                        </div>

                    </div>
                    

                </div>
                
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '80px', alignSelf: 'center', justifyContent: 'space-between', marginTop:"20px"}}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%', justifyContent: 'center', marginLeft: '20px'}}>
                        <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center" }}>Total Asset View Time</span>
                        <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center", marginTop:"10px" }}>{(item.totalAssetViewTime!=null)?item.totalAssetViewTime.toFixed(3):0}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%', justifyContent: 'center', marginRight: '20px' }}>
                        <span style={{ display: 'flex', fontSize: '18px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center" }}>Total Spawn/Seen Count</span>
                        <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center", marginTop:"10px" }}>{item.totalNumberOfTimesSeen}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%', justifyContent: 'center', marginRight: '20px' }}>
                        <span style={{ display: 'flex', fontSize: '18px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center" }}>Total Interaction Count</span>
                        <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center", marginTop:"10px" }}>{item.interactionCountTotal}</span>
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '80px', alignSelf: 'center', justifyContent: 'space-between',paddingBottom:'20px', marginTop:"10px"}}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%', justifyContent: 'center', marginLeft: '20px'}}>
                        <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center" }}>Avg View Time / User</span>
                        <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center", marginTop:"10px" }}>{(item.avgViewTimePerUser!=null)?item.avgViewTimePerUser.toFixed(3):null}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%', justifyContent: 'center', marginRight: '20px' }}>
                        <span style={{ display: 'flex', fontSize: '18px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center" }}>Avg Seen Count / User</span>
                        <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center", marginTop:"10px" }}>{(item.avgSeenCountPerUser!=null)?item.avgSeenCountPerUser.toFixed(3):null}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%', justifyContent: 'center', marginRight: '20px' }}>
                        <span style={{ display: 'flex', fontSize: '18px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center" }}>Avg Interaction Count / User</span>
                        <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center", marginTop:"10px" }}>{(item.avgInteractionCount!=null)?item.avgInteractionCount.toFixed(3):null}</span>
                    </div>
                </div>
            </div>
        );
    }

    function UserAssetMetricsList({userAssetMetricsData}) {
    
        const items = userAssetMetricsData
    
        return (
            <div className="asset-item-scroll-container">
                {items.map((item, index) => (
                    <UserAssetMetricsListItem key={index} item={item} index={index} />
                ))}
            </div>
        );
    }
    
    function UserAssetMetricsListItem({ item, index}) {
        const [userMetricsButtonHover, setUserMetricsButtonHover] = useState(false);
    
        const handleMouseEnterUserMetricsButton = () => {
            setUserMetricsButtonHover(true);
        };
    
        const handleMouseLeaveUserMetricsButton = () => {
            setUserMetricsButtonHover(false);
        };
    
        return (
            <div
                key={index}
                className={`asset-metric-list-item`}
                style={{flexDirection:'column'}}
            >     
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '80px', alignSelf: 'center', justifyContent: 'center', marginTop:"20px"}}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%', justifyContent: 'center', marginRight: '20px'}}>
                        <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center" }}>User-Id</span>
                        <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center", marginTop:"10px" }}>{item.user.userId}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%', justifyContent: 'center', marginLeft: '20px' }}>
                        <span style={{ display: 'flex', fontSize: '18px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center" }}>User-Mail</span>
                        <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center", marginTop:"10px" }}>{item.user.emailId}</span>
                    </div>
                </div>         
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '80px', alignSelf: 'center', justifyContent: 'space-between', marginTop:"10px",paddingBottom:'20px',}}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%', justifyContent: 'center', marginLeft: '20px'}}>
                        <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center" }}>Asset View Time</span>
                        <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center", marginTop:"10px" }}>{item.totalAssetViewTime}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%', justifyContent: 'center', marginRight: '20px' }}>
                        <span style={{ display: 'flex', fontSize: '18px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center" }}>Asset Seen Count</span>
                        <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center", marginTop:"10px" }}>{item.totalNumberOfTimesSeen}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%', justifyContent: 'center', marginRight: '20px' }}>
                        <span style={{ display: 'flex', fontSize: '18px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center" }}>Interaction Count</span>
                        <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#F6F0ED', alignSelf: "center", marginTop:"10px" }}>{item.interactionCount}</span>
                    </div>
                </div>
            </div>
        );
    }
    



    return(
        <div className='CampaignFinal'>


            {(campaignDetails!=undefined)?
            <div style={{display:"flex", width:'100%', flexDirection:'row', justifyContent:'flex-start', alignSelf:'center'}}>
                <span style={{display:'flex',fontSize:'60px', fontFamily:'Comfortaa', fontWeight:'bold', marginLeft:'40px', marginTop:'25px', color:'#F6F0ED'}}>campaign metrics</span>
                <div style={{ display: 'flex', flexDirection: 'row', width: '350px', height:'40px',alignSelf: 'center',  justifyContent: 'center' ,backgroundColor:'#DAE011', borderRadius:"20px", marginTop:"25px", marginLeft:'40px'}}>
                    <span style={{ display: 'flex', fontSize: '18px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: '#1a1a1a', alignSelf: "center" }}>{campaignDetails.advertiser.emailId}</span>
                </div>
            </div>:null}
        
            {(campaignDetails!=undefined)?
            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'90%', marginLeft:'40px'}}>
                <div style={{width:'100%', height:'40px', marginTop:'20px', flexDirection:"row"}}>
                    <div style={{display:'flex', flexDirection:'row', width:'90%', height:'100%', alignSelf:'center', justifyContent:'space-between'}}>
                            
                            <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-start'}}>
                                <span style={{display:'flex',fontSize:'25px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#F6F0ED', alignSelf:"center"}}>{campaignDetails.game.gameName}</span>
                            </div>

                            <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-start', marginLeft:"10px"}}>
                                <span style={{display:'flex',fontSize:'20px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#F6F0ED', alignSelf:"center"}}>{storedCampaignId}</span>
                            </div>

                            {(campaignDetails.startDate != null)?
                            <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-start'}}>
                                <span style={{display:'flex',fontSize:'20px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#F6F0ED', alignSelf:"center"}}>{addDaysAndFormat(campaignDetails.startDate,0)} to {addDaysAndFormat(campaignDetails.startDate,campaignDetails.durationDays)}</span>
                            </div>:null}


                            <button style={{display:'flex', flexDirection:'row', width:'100px', justifyContent:'center', backgroundColor:linkButtonHover?'#DAE011':"#1d1d1d", height:'100%', alignSelf:'center', borderRadius:'16px', cursor:'pointer', borderColor:"transparent"}}
                            onClick={()=>{
                                window.open(campaignDetails.game.gameUrl, '_blank');
                            }}>
                                <span style={{display:'flex',fontSize:'22px', fontFamily:'Comfortaa', fontWeight:'bold', color:linkButtonHover?'#1d1d1d':'#F6F0ED', alignSelf:"center"}}>site</span>
                            </button>

                    </div>
                </div>
            </div>:null}

            <div style={{display:'flex', width:'90%', height:'75%', borderRadius:'20px', marginLeft:'35px', marginTop:'20px', flexDirection:'column'}}>
            
            <div style={{display:"flex", width:'100%', flexDirection:'row'}}>
                    
                    
                    <button
                        className="user-metrics-button"
                        style={{
                            backgroundColor:(metricsFilter == 'OVERALL')?'#DAE011':'#1a1a1a',
                            color:(metricsFilter == 'OVERALL')?'#1a1a1a':'white'
                        }}
                        onClick={()=>{
                            setMetricsFilter('OVERALL');
                            setUserMetricsList(false);
                            setUserRewardsList(false);
                        }}>
                        overall
                    </button>
                    <button
                        className="assets-metrics-button"
                        style={{
                            backgroundColor:(metricsFilter == 'ASSETS')?'#DAE011':'#1a1a1a',
                            color:(metricsFilter == 'ASSETS')?'#1a1a1a':'white'
                        }}
                        onClick={()=>{
                            setMetricsFilter('ASSETS');
                            setUserMetricsList(false);
                            setUserRewardsList(false);
                        }}>
                        assets
                    </button>
                    <button
                        className="rewards-metrics-button"
                        style={{
                            backgroundColor:(metricsFilter == 'REWARDS')?'#DAE011':'#1a1a1a',
                            color:(metricsFilter == 'REWARDS')?'#1a1a1a':'white'
                        }}
                        onClick={()=>{
                            setMetricsFilter('REWARDS');
                            setUserMetricsList(false);
                            setUserRewardsList(false);
                        }}>
                        rewards
                    </button>
                    <button
                        className="reload-button"
                        style={{
                            backgroundColor:(reloadButtonHighlight)?'#DAE011':'#1a1a1a',
                            color:(reloadButtonHighlight)?'#1a1a1a':'white'
                        }}
                        onClick={async ()=>{
                            console.log('clicked');
                            setReloadButtonHighlight(true);
                            setTimeout(()=>{setReloadButtonHighlight(false)},1500);
                            let campMet = await fetchCampaignMetricsQuery(storedCampaignId);
                            setCampaignMetrics(campMet.campaignMetrics);
                            setCampaignAssetMetrics(campMet.campaignAssets);
                            setCampaignRewardMetrics(campMet.campaignRewards);
                        }}>
                         <img src={(reloadButtonHighlight)?reloadBlackIcon:reloadWhiteIcon} style={{width:'28px', alignSelf:'center'}}></img>
                    </button>
            </div>


            {(metricsFilter == 'OVERALL' && (campaignMetrics != undefined && campaignMetrics!=null) )?
            <>
            <div style={{display:'flex',width:'100%', flexDirection:'row',  marginTop:'20px'}}>
                <div style={{display:'flex',width:'300px', height:'110px', backgroundColor:'#1d1d1d', borderRadius:'20px', flexDirection:'column', justifyContent:"center"}}>

                    <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center'}}>
                        <span style={{display:'flex',fontSize:'20px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#F6F0ED', alignSelf:"center"}}>Total User Reached</span>
                    </div> 

                    <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center', marginTop:'20px'}}>
                        <span style={{display:'flex',fontSize:'20px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#F6F0ED', alignSelf:"center"}}>{campaignMetrics.totalUserReached}</span>
                    </div> 
                
                </div>

                <div style={{display:'flex',width:'300px', height:'110px', backgroundColor:'#1d1d1d', borderRadius:'20px', flexDirection:'column', justifyContent:"center", marginLeft:"20px"}}>

                    <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center'}}>
                        <span style={{display:'flex',fontSize:'20px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#F6F0ED', alignSelf:"center"}}>Total View Time</span>
                    </div> 

                    <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center', marginTop:'20px'}}>
                        <span style={{display:'flex',fontSize:'20px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#F6F0ED', alignSelf:"center"}}>{(campaignMetrics.totalAssetViewTime!=null)?campaignMetrics.totalAssetViewTime.toFixed(3):0} secs</span>
                    </div> 

                </div>

                <div style={{display:'flex',width:'300px', height:'110px', backgroundColor:'#1d1d1d', borderRadius:'20px', flexDirection:'column', justifyContent:"center", marginLeft:"20px"}}>

                    <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center'}}>
                        <span style={{display:'flex',fontSize:'20px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#F6F0ED', alignSelf:"center"}}>Total Canvas Seen</span>
                    </div> 

                    <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center', marginTop:'20px'}}>
                        <span style={{display:'flex',fontSize:'20px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#F6F0ED', alignSelf:"center"}}>{campaignMetrics.canvasSeenCount}</span>
                    </div> 

                </div>
    
            </div>

            <div style={{display:'flex',width:'100%', flexDirection:'row',  marginTop:'20px'}}>
                <div style={{display:'flex',width:'300px', height:'110px', backgroundColor:'#1d1d1d', borderRadius:'20px', flexDirection:'column', justifyContent:"center"}}>

                    <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center'}}>
                        <span style={{display:'flex',fontSize:'20px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#F6F0ED', alignSelf:"center"}}>Total Redirects</span>
                    </div> 

                    <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center', marginTop:'20px'}}>
                        <span style={{display:'flex',fontSize:'20px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#F6F0ED', alignSelf:"center"}}>{campaignMetrics.callToActionCount}</span>
                    </div> 
                
                </div>

                <div style={{display:'flex',width:'300px', height:'110px', backgroundColor:'#1d1d1d', borderRadius:'20px', flexDirection:'column', justifyContent:"center", marginLeft:'20px'}}>

                    <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center'}}>
                        <span style={{display:'flex',fontSize:'20px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#F6F0ED', alignSelf:"center"}}>Total Reward Claims</span>
                    </div> 

                    <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center', marginTop:'20px'}}>
                        <span style={{display:'flex',fontSize:'20px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#F6F0ED', alignSelf:"center"}}>{campaignMetrics.totalRewardClaims}</span>
                    </div> 

                </div>

                <div style={{display:'flex',width:'300px', height:'110px', backgroundColor:'#1d1d1d', borderRadius:'20px', flexDirection:'column', justifyContent:"center", marginLeft:'20px'}}>

                    <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center'}}>
                        <span style={{display:'flex',fontSize:'20px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#F6F0ED', alignSelf:"center"}}>Total Gameplay time</span>
                    </div> 

                    <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center', marginTop:'20px'}}>
                        <span style={{display:'flex',fontSize:'20px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#F6F0ED', alignSelf:"center"}}>{(campaignMetrics.totalGamePlayDuration!=null)?campaignMetrics.totalGamePlayDuration.toFixed(3):0} secs</span>
                    </div> 

                </div>
            </div>

            <div style={{display:'flex',width:'100%', flexDirection:'row',  marginTop:'20px'}}>
                <div style={{display:'flex',width:'300px', height:'110px', backgroundColor:'#1d1d1d', borderRadius:'20px', flexDirection:'column', justifyContent:"center"}}>

                    <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center'}}>
                        <span style={{display:'flex',fontSize:'20px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#F6F0ED', alignSelf:"center"}}>Total Reward Unlocked</span>
                    </div> 

                    <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center', marginTop:'20px'}}>
                        <span style={{display:'flex',fontSize:'20px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#F6F0ED', alignSelf:"center"}}>{campaignMetrics.totalUnlockedRewards}</span>
                    </div> 
                
                </div>

                <div style={{display:'flex',width:'300px', height:'110px', backgroundColor:'#1d1d1d', borderRadius:'20px', flexDirection:'column', justifyContent:"center", marginLeft:'20px'}}>

                    <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center'}}>
                        <span style={{display:'flex',fontSize:'20px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#F6F0ED', alignSelf:"center"}}>Avg View Time / User</span>
                    </div> 

                    <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center', marginTop:'20px'}}>
                        <span style={{display:'flex',fontSize:'20px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#F6F0ED', alignSelf:"center"}}>{(campaignMetrics.avgViewTimePerUser!=null)?campaignMetrics.avgViewTimePerUser.toFixed(3):0} secs</span>
                    </div> 

                </div>

                <div style={{display:'flex',width:'300px', height:'110px', backgroundColor:'#1d1d1d', borderRadius:'20px', flexDirection:'column', justifyContent:"center", marginLeft:'20px'}}>

                    <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center'}}>
                        <span style={{display:'flex',fontSize:'20px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#F6F0ED', alignSelf:"center"}}>Avg Interactions / User</span>
                    </div> 

                    <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center', marginTop:'20px'}}>
                        <span style={{display:'flex',fontSize:'20px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#F6F0ED', alignSelf:"center"}}>{campaignMetrics.avgInteractionsPerUser}</span>
                    </div> 

                </div>
            </div>

            <div style={{display:'flex',width:'100%', flexDirection:'row',  marginTop:'20px'}}>
                <div style={{display:'flex',width:'300px', height:'110px', backgroundColor:'#1d1d1d', borderRadius:'20px', flexDirection:'column', justifyContent:"center"}}>

                    <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center'}}>
                        <span style={{display:'flex',fontSize:'20px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#F6F0ED', alignSelf:"center"}}>Avg Assets Seen / User</span>
                    </div> 

                    <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center', marginTop:'20px'}}>
                        <span style={{display:'flex',fontSize:'20px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#F6F0ED', alignSelf:"center"}}>{campaignMetrics.avgAssetsSeenPerUser}</span>
                    </div> 
                
                </div>

                <div style={{display:'flex',width:'300px', height:'110px', backgroundColor:'#1d1d1d', borderRadius:'20px', flexDirection:'column', justifyContent:"center", marginLeft:'20px'}}>

                    <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center'}}>
                        <span style={{display:'flex',fontSize:'20px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#F6F0ED', alignSelf:"center"}}>Avg Canvas Seen / User</span>
                    </div> 

                    <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center', marginTop:'20px'}}>
                        <span style={{display:'flex',fontSize:'20px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#F6F0ED', alignSelf:"center"}}>{campaignMetrics.avgCanvasSeenPerUser}</span>
                    </div> 

                </div>

                <div style={{display:'flex',width:'300px', height:'110px', backgroundColor:'#1d1d1d', borderRadius:'20px', flexDirection:'column', justifyContent:"center", marginLeft:'20px'}}>

                    <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center'}}>
                        <span style={{display:'flex',fontSize:'20px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#F6F0ED', alignSelf:"center"}}>Avg Gameplay time / User</span>
                    </div> 

                    <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center', marginTop:'20px'}}>
                        <span style={{display:'flex',fontSize:'20px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#F6F0ED', alignSelf:"center"}}>{(campaignMetrics.avgGamePlayDuration!=null)?campaignMetrics.avgGamePlayDuration.toFixed(3):null}</span>
                    </div> 

                </div>
            </div>
            </>:
            (metricsFilter == 'ASSETS' && (campaignAssetMetrics != undefined && campaignAssetMetrics!=null) )?
            <>
            <div style={{display:"flex", width:'100%', height:'90%', flexDirection:'row'}}>
                <div style={{display:"flex", width:'100%', height:'100%',flexDirection:'column'}}>
                    
                    {(viewUserMetricsList == true)?
                    <>            
                    <button className="back-metrics-button"
                    onClick={()=>{
                        setUserMetricsList(false);
                    }}>
                        back
                    </button>
                    <UserAssetMetricsList userAssetMetricsData={campaignUserAssetMetrics}/>
                    </>:
                    <AssetMetricsList assetMetricsData={campaignAssetMetrics}/>}
                </div>
            </div> 
            </>:
            (metricsFilter == 'REWARDS' && (campaignRewardMetrics != undefined && campaignRewardMetrics!=null) )?
            <>
            <div style={{display:"flex", width:'100%', height:'90%', flexDirection:'row'}}>
                <div style={{display:"flex", width:'100%', height:'100%',flexDirection:'column'}}>
                {(viewUserRewardsList == true)?
                    <>            
                    <button className="back-metrics-button"
                    onClick={()=>{
                        setUserRewardsList(false);
                    }}>
                        back
                    </button>
                    <UserRewardList userRewardsData={campaignUserRewardsMetrics}/>
                    </>:
                    <RewardMetricsList rewardDataList={campaignRewardMetrics}/>}
                </div>
            </div>
            </>
            :null
            }


            </div>


        </div>
    );
}

export default CampaignMetricsView;