import React, { useEffect, useRef, useState } from "react";
import CreateCampaign1 from './CreateCampaign1'
import CreateCampaign2 from './CreateCampaign2'
import LinkAssetsCampaign from './LinkAssetsCampaign'
import CampaignFinal from './CampaignFinal'
import CampaignMetricsView from "./CampaignMetricsView";
import ListCampaigns from './ListCampaigns'

function AdvertiserApp(){

    return(
        <div style={{display:'flex',width:'90%', height:'100%'}}>
           
            {/* <ListCampaigns/> */}
            {/* <CreateCampaign1/> */}
            {/* <CreateCampaign2/>   */}
            {/* <LinkAssetsCampaign/> */}
            {/* <CampaignFinal/> */}
            <CampaignMetricsView/>

        </div>
    );
}

export default AdvertiserApp;