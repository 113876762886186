import React, { useEffect, useRef, useState } from "react";
import './CampaignFinal.css'


function ListItem({item}){
    const [editButtonHover,setEditButtonHover] = useState(false);
  
    const handleMouseEnterEditButton = () => {
      setEditButtonHover(true);
    };
    const handleMouseLeaveEditButton = () => {
      setEditButtonHover(false);
    };

    return(
        <div key={item} className="list-item">
            <div style={{display:'flex', flexDirection:'row', width:'96%', height:'80%', alignSelf:'center', justifyContent:'space-between'}}>
            
                <div style={{display:'flex', flexDirection:'row', width:'20%', justifyContent:'center'}}>
                    <span style={{display:'flex',fontSize:'18px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#F6F0ED', alignSelf:"center"}}>Impossible Car Track</span>
                </div>

                <div style={{display:'flex', flexDirection:'row', width:'20%', justifyContent:'center'}}>
                    <span style={{display:'flex',fontSize:'18px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#F6F0ED', alignSelf:"center"}}>aso91-iopqwe9-yqwiun</span>
                </div>

                <div style={{display:'flex', flexDirection:'row', width:'20%', justifyContent:'center'}}>
                    <span style={{display:'flex',fontSize:'18px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#F6F0ED', alignSelf:"center"}}>enabled</span>
                </div>

                <div style={{display:'flex', flexDirection:'row', width:'20%', justifyContent:'center'}}>
                <span style={{display:'flex',fontSize:'18px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#DAE011', alignSelf:"center"}}>active</span>
                </div>

                <button style={{display:'flex', flexDirection:'row', width:'10%', justifyContent:'center', backgroundColor:editButtonHover?'#DAE011':"#0d0d0d", height:'70%', alignSelf:'center', borderRadius:'16px', cursor:'pointer', borderColor:"transparent"}}
                onMouseEnter={handleMouseEnterEditButton}
                onMouseLeave={handleMouseLeaveEditButton}>
                    <span style={{display:'flex',fontSize:'18px', fontFamily:'Comfortaa', fontWeight:'bold', color:editButtonHover?'#0d0d0d':'#F6F0ED', alignSelf:"center"}}>edit</span>
                </button>

            </div> 
        </div>
    );
}


function VerticalList() {
    const items = Array.from({ length: 2 }).map((_, index) => `Item ${index + 1}`);

    return (
        <div className="scroll-container">
            {items.map(item => (
                <ListItem item={item}/>
            ))}
        </div>
    );
}

function CampaignNameInput() {
    const [inputValue, setInputValue] = useState("Coke");
    const [isInputFocused, setInputFocused] = useState(false);

    const handleInputChange = (e) => {
        // setInputValue(e.target.value);
    };

    const handleInputFocus = () => {
        setInputFocused(true);
    };

    const handleInputBlur = () => {
        setInputFocused(false);
    };

    return (
        <div className="campaign-name-input">
            <span style={{display:'flex',fontSize:'28px', fontFamily:'Comfortaa', fontWeight:'bold', marginLeft:'10px', color:'#F6F0ED', marginBottom:'20px'}}>campaign</span>
            <input
                type="text"
                placeholder="enter campaign name"
                value={inputValue}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
            />
        </div>
    );
}

function  StatusInput() {
    const [inputValue, setInputValue] = useState("Asset Updates");
    const [isInputFocused, setInputFocused] = useState(false);

    const handleInputChange = (e) => {
        // setInputValue(e.target.value);
    };

    const handleInputFocus = () => {
        setInputFocused(true);
    };

    const handleInputBlur = () => {
        setInputFocused(false);
    };

    return (
        <div className="status-input">
            <span style={{display:'flex',fontSize:'28px', fontFamily:'Comfortaa', fontWeight:'bold', marginLeft:'10px', color:'#F6F0ED', marginBottom:'20px'}}>status</span>
            <input
                type="text"
                placeholder="enter campaign name"
                value={inputValue}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
            />
        </div>
    );
}

function CampaignDurationSelection({ onDurationSelect }) {
    const [selectedDuration, setSelectedDuration] = useState(7);

    const handleDurationSelect = (duration) => {
        setSelectedDuration(duration);
        if (onDurationSelect) {
            onDurationSelect(duration);
        }
    };

    return (
        <div className="campaign-duration-selection">
            <div className="duration-options">
                <button
                    className={`duration-option ${selectedDuration === 7 ? 'selected' : ''}`}
                    onClick={() => handleDurationSelect(7)}
                >
                    7 Days
                </button>
            </div>
        </div>
    );
}

function ListAssetItem1({ item, index, selectedItems, handleItemSelect }) {
    const [editButtonHover, setEditButtonHover] = useState(false);

    const handleMouseEnterEditButton = () => {
        setEditButtonHover(true);
    };

    const handleMouseLeaveEditButton = () => {
        setEditButtonHover(false);
    };

    const handleItemClick = () => {
        handleItemSelect(index);
    };

    const isSelected = selectedItems.includes(index);

    return (
        <button
            key={index}
            className={`list-item-selected`}
            onClick={handleItemClick}
        >
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50px', alignSelf: 'center', justifyContent: 'space-between'}}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '30%', justifyContent: 'center', marginLeft: '20px' }}>
                    <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: isSelected ? '#0d0d0d' : '#F6F0ED', alignSelf: "center" }}>fish-bone</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', width: '30%', justifyContent: 'center', marginRight: '20px' }}>
                    <span style={{ display: 'flex', fontSize: '18px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: isSelected ? '#0d0d0d' : '#F6F0ED', alignSelf: "center" }}>collectible</span>
                </div>
            </div>
        </button>
    );
}

function CombinedList1() {
    const [selectedItems, setSelectedItems] = useState([0,1,2,3,4,5,6,7,8,9,10]);

    const handleItemSelect = (index) => {
        // if (selectedItems.includes(index)) {
        //     setSelectedItems(selectedItems.filter((item) => item !== index));
        // } else {
        //     setSelectedItems([...selectedItems, index]);
        // }
    };

    const items = Array.from({length: 10 }).map((_, index) => `Item ${index + 1}`);

    return (
        <div className="scroll-container">
            {items.map((item, index) => (
                <ListAssetItem1 key={index} item={item} index={index} selectedItems={selectedItems} handleItemSelect={handleItemSelect} />
            ))}
        </div>
    );
}

function ListAssetItem2({ item, index, selectedItems, handleItemSelect }) {
    const [editButtonHover, setEditButtonHover] = useState(false);

    const handleMouseEnterEditButton = () => {
        setEditButtonHover(true);
    };

    const handleMouseLeaveEditButton = () => {
        setEditButtonHover(false);
    };

    const handleItemClick = () => {
        handleItemSelect(index);
    };

    const isSelected = selectedItems.includes(index);

    return (
        <button
            key={index}
            className={`list-item-selected`}
            onClick={handleItemClick}
        >
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '50px', alignSelf: 'center', justifyContent: 'space-between'}}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '30%', justifyContent: 'center', marginLeft: '20px' }}>
                    <span style={{ display: 'flex', fontSize: '20px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: isSelected ? '#0d0d0d' : '#F6F0ED', alignSelf: "center" }}>200OFF</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', width: '30%', justifyContent: 'center', marginRight: '20px' }}>
                    <span style={{ display: 'flex', fontSize: '18px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: isSelected ? '#0d0d0d' : '#F6F0ED', alignSelf: "center" }}>1000</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', width: '30%', justifyContent: 'center', marginRight: '20px' }}>
                    <span style={{ display: 'flex', fontSize: '18px', fontFamily: 'Comfortaa', fontWeight: 'bold', color: isSelected ? '#0d0d0d' : '#F6F0ED', alignSelf: "center" }}>5000</span>
                </div>
            </div>
        </button>
    );
}

function CombinedList2() {
    const [selectedItems, setSelectedItems] = useState([0,1,2,3,4,5,6,7,8,9,10]);

    const handleItemSelect = (index) => {
        // if (selectedItems.includes(index)) {
        //     setSelectedItems(selectedItems.filter((item) => item !== index));
        // } else {
        //     setSelectedItems([...selectedItems, index]);
        // }
    };

    const items = Array.from({ length: 10 }).map((_, index) => `Item ${index + 1}`);

    return (
        <div className="scroll-container">
            {items.map((item, index) => (
                <ListAssetItem2 key={index} item={item} index={index} selectedItems={selectedItems} handleItemSelect={handleItemSelect} />
            ))}
        </div>
    );
}



function CampaignFinal(){


  let gameName = "Trash Cat";
  let gameDownloads = "10M";
  let dailyActiveUsers = "500K"

  const [gameFilter, setGameFilter] = useState('REGISTERED');
  const [registerGameButtonHover,setRegisterGameButtonHover] = useState(false);
  const [registeredGameButtonHover,setRegisteredGameButtonHover] = useState(false);
  const [enabledGameButtonHover,setEnabledGameButtonHover] = useState(false);
  const [activeGameButtonHover,setActiveGameButtonHover] = useState(false);

  const [linkButtonHover,setLinkButtonHover] = useState(false);
  
  const handleMouseEnterRegisterGameButton = () => {
    setRegisterGameButtonHover(true);
  };
  const handleMouseLeaveRegisterGameButton = () => {
    setRegisterGameButtonHover(false);
  };

  const handleMouseEnterRegisteredGameButton = () => {
    setRegisteredGameButtonHover(true);
  };
  const handleMouseLeaveRegisteredGameButton = () => {
    setRegisteredGameButtonHover(false);
  };

  const handleMouseEnterEnabledGameButton = () => {
    setEnabledGameButtonHover(true);
  };
  const handleMouseLeaveEnabledGameButton = () => {
    setEnabledGameButtonHover(false);
  };

  const handleMouseEnterLinkButton = () => {
    setLinkButtonHover(true);
  };
  const handleMouseLeaveLinkButton = () => {
    setLinkButtonHover(false);
  };



    return(
        <div className='CampaignFinal'>


            <div style={{display:"flex", width:'100%', flexDirection:'row', justifyContent:'space-between', alignSelf:'center'}}>
                <span style={{display:'flex',fontSize:'60px', fontFamily:'Comfortaa', fontWeight:'bold', marginLeft:'40px', marginTop:'25px', color:'#F6F0ED'}}>create campaign</span>
                <button
                    className="create-button"
                    onClick={()=>{}}>
                    start
                </button>
            </div>
            

            <span style={{display:'flex',fontSize:'40px', fontFamily:'Comfortaa', fontWeight:'bold', marginLeft:'40px', marginTop:'30px', color:'#F6F0ED'}}>game</span>
            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'90%', marginLeft:'40px'}}>
                <div className="list-item" style={{width:'100%', height:'70px', marginTop:'20px'}}>
                    <div style={{display:'flex', flexDirection:'row', width:'96%', height:'80%', alignSelf:'center', justifyContent:'space-between'}}>
                            
                            <div style={{display:'flex', flexDirection:'row', width:'20%', justifyContent:'center'}}>
                                <span style={{display:'flex',fontSize:'20px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#F6F0ED', alignSelf:"center"}}>{gameName}</span>
                            </div>

                            <div style={{display:'flex', flexDirection:'row', width:'20%', justifyContent:'center'}}>
                                <span style={{display:'flex',fontSize:'18px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#F6F0ED', alignSelf:"center"}}>{gameDownloads} downloads</span>
                            </div>

                            <div style={{display:'flex', flexDirection:'row', width:'20%', justifyContent:'center'}}>
                                <span style={{display:'flex',fontSize:'20px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#F6F0ED', alignSelf:"center"}}>{dailyActiveUsers} daily users</span>
                            </div>


                            <button style={{display:'flex', flexDirection:'row', width:'10%', justifyContent:'center', backgroundColor:linkButtonHover?'#DAE011':"#0d0d0d", height:'70%', alignSelf:'center', borderRadius:'16px', cursor:'pointer', borderColor:"transparent"}}
                            onMouseEnter={handleMouseEnterLinkButton}
                            onMouseLeave={handleMouseLeaveLinkButton}>
                                <span style={{display:'flex',fontSize:'18px', fontFamily:'Comfortaa', fontWeight:'bold', color:linkButtonHover?'#0d0d0d':'#F6F0ED', alignSelf:"center"}}>site</span>
                            </button>


                            <button style={{display:'flex', flexDirection:'row', width:'15%', justifyContent:'center', backgroundColor:'#DAE011', height:'70%', alignSelf:'center', borderRadius:'16px', cursor:'pointer', borderColor:"transparent"}}>
                                <span style={{display:'flex',fontSize:'20px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#0d0d0d', alignSelf:"center"}}>selected</span>
                            </button>

                    </div> 
                </div>
            </div>

            <div style={{display:'flex', width:'90%', height:'61%', borderRadius:'20px', marginLeft:'35px', marginTop:'30px', flexDirection:'column'}}>
            
            <div style={{display:"flex", width:'100%', flexDirection:'row'}}>
                <CampaignNameInput/>
                <div style={{display:"flex", width:'30%', height:'110px', flexDirection:'column', alignSelf:'center', justifyContent:'center', marginLeft:"50px"}}>
                    <span style={{display:'flex',fontSize:'25px', fontFamily:'Comfortaa', fontWeight:'bold', marginLeft:'10px', color:'#F6F0ED'}}>duration</span>
                    <CampaignDurationSelection/>
                </div>
                <div style={{display:"flex", width:'30%', height:'110px', flexDirection:'column', alignSelf:'center', justifyContent:'center', marginLeft:"50px"}}>
                    <StatusInput/>
                </div>
            </div>

            <div style={{display:"flex", width:'100%', height:'79%', flexDirection:'row'}}>
                <div style={{display:"flex", width:'48%', height:'90%',flexDirection:'column'}}>
                <span style={{display:'flex',fontSize:'25px', fontFamily:'Comfortaa', fontWeight:'bold', marginLeft:'10px', color:'#F6F0ED', marginTop:'40px'}}>selected assets</span>
                    <CombinedList1/>
                </div>
                <div style={{display:"flex", width:'50%', height:'90%',flexDirection:'column', marginLeft:'20px'}}>
                <span style={{display:'flex',fontSize:'25px', fontFamily:'Comfortaa', fontWeight:'bold', marginLeft:'10px', color:'#F6F0ED', marginTop:'40px'}}>created rewards</span>
                    <CombinedList2/>
                </div>
            </div>



            </div>


        </div>
    );
}

export default CampaignFinal;