import './css/App.css';
import './css/NavBar.css';
import React, { useEffect, useRef, useState } from "react";
import settingBlackIcon from './asset/settingBlackIcon.png'
import settingWhiteIcon from './asset/settingWhiteIcon.png'
import logoutWhiteIcon from './asset/logoutWhiteIcon.png'
import logoutBlackIcon from './asset/logoutBlackIcon.png'
import metricsWhiteIcon from './asset/metricsWhiteIcon.png'
import metricsBlackIcon from './asset/metricsBlackIcon.png'
import GamesApp from './screens/games/GamesApp';
import AdvertiserApp from './screens/advertiser/AdvertiserApp';
import {LOGIN_ADVERTISER_CAMPAIGN_METRICS} from './Graphql/Query';
import { globalApolloClient } from "./index";


function App() {

  const [screen, setScreen] = useState('LOGIN');


  function NavBar() {

    const [currentTab,setCurrentTab] = useState('METRICS');
  
    const [gameButtonHover,setGameButtonHover] = useState(false);
    const [metricsButtonHover,setMetricsButtonHover] = useState(false);
    const [settingsButtonHover,setSettingsButtonHover] = useState(false);
    
    const handleMouseEnterGameButton = () => {
      setGameButtonHover(true);
    };
    const handleMouseLeaveGameButton = () => {
      setGameButtonHover(false);
    };
  
    const handleMouseEnterMetricsButton = () => {
      setMetricsButtonHover(true);
    };
    const handleMouseLeaveMetricsButton = () => {
      setMetricsButtonHover(false);
    };
  
    const handleMouseEnterSettingsButton = () => {
      setSettingsButtonHover(true);
    };
    const handleMouseLeaveSettingsButton = () => {
      setSettingsButtonHover(false);
    };
  
    
  
    return (
      <div className="NavBar">
        <div style={{display:'flex', flexDirection:'column'}}>
        <div className="SpacesLogo">
          <div style={{display:'flex', position:'relative', width:'50px', height:'50px', alignSelf:'center'}}>
            <span style={{display:'flex', position:'absolute',alignSelf:'center', fontFamily:'Black Ops One', fontWeight:'regular', fontSize:'70px', color:'rgba(218, 238, 1,1)', zIndex:2, left:0, bottom:0}}>s</span>
            <span style={{display:'flex', position:'absolute',alignSelf:'center', fontFamily:'Black Ops One', fontWeight:'regular', fontSize:'70px', color:'rgba(82, 154, 89, 0.6)', zIndex:1, left:4,bottom:0}}>s</span>
          </div>
        </div>
        {/* <button className="NavBarButton" style={{backgroundColor:gameButtonHover || (currentTab == 'GAMES')?'#DAE011':'#0d0d0d', cursor:'pointer'}}
          onMouseEnter={handleMouseEnterGameButton}
          onMouseLeave={handleMouseLeaveGameButton}
          onClick={()=>{
            setCurrentTab('GAMES')
          }}>
          {(gameButtonHover|| (currentTab == 'GAMES'))?
          <img src={gamesBlackIcon} style={{width:'40px', height:'40px', alignSelf:'center'}}></img>
          :
          <img src={gamesWhiteIcon} style={{width:'40px', height:'40px', alignSelf:'center'}}></img>}
        </button> */}
        <button className="NavBarButton" style={{backgroundColor:metricsButtonHover || (currentTab == 'METRICS')?'#DAE011':'#0d0d0d', cursor:'pointer'}}
          onMouseEnter={handleMouseEnterMetricsButton}
          onMouseLeave={handleMouseLeaveMetricsButton}
          onClick={()=>{
            setCurrentTab('METRICS')
          }}>
          {(metricsButtonHover || (currentTab == 'METRICS'))?
          <img src={metricsBlackIcon} style={{width:'30px', height:'30px', alignSelf:'center'}}></img>
          :
          <img src={metricsWhiteIcon} style={{width:'30px', height:'30px', alignSelf:'center'}}></img>}
        </button>
        </div>
  
        <button className="NavBarButton" style={{backgroundColor:settingsButtonHover || (currentTab == 'SETTINGS')?'#DAE011':'#0d0d0d', cursor:'pointer',marginBottom:'60px'}}
          onMouseEnter={handleMouseEnterSettingsButton}
          onMouseLeave={handleMouseLeaveSettingsButton}
          onClick={()=>{
            //setCurrentTab('SETTINGS')
            setScreen('LOGIN')
          }}>
          {(settingsButtonHover || (currentTab == 'SETTINGS'))?
          <img src={logoutBlackIcon} style={{width:'40px', height:'40px', alignSelf:'center'}}></img>
          :
          <img src={logoutWhiteIcon} style={{width:'40px', height:'40px', alignSelf:'center'}}></img>}
        </button>
      </div>
    );
  
  }

  function MainScreen(){
    return(
      // <GamesApp/>
      <AdvertiserApp/>
    )
  }
  
  function LoginScreen(){
    const [loginError, setLoginError] = useState(false);
    let campaignId = useRef(localStorage.getItem('campaignId'));
    let campaignAccessKey = useRef(localStorage.getItem('campaignAccessKey'));
  
    function CampaignIdInput() {
      const [inputValue, setInputValue] = useState(localStorage.getItem('campaignId'));
      const [isInputFocused, setInputFocused] = useState(false);
    
      const handleInputChange = (e) => {
          campaignId.current = e.target.value
          setInputValue(e.target.value);
      };
    
      const handleInputFocus = () => {
          setInputFocused(true);
      };
    
      const handleInputBlur = () => {
          setInputFocused(false);
      };
    
      return (
          <div className="campaign-name-input" style={{alignSelf:'center',marginRight:'20px'  }}>
              <input
                  type="text"
                  placeholder="campaign id"
                  value={inputValue}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
              />
          </div>
      );
    }
    
    function CampaignViewAccesskeyInput() {
      const [inputValue, setInputValue] = useState(localStorage.getItem('campaignAccessKey'));
      const [isInputFocused, setInputFocused] = useState(false);
      const handleInputChange = (e) => {
          campaignAccessKey.current = e.target.value;
          setInputValue(e.target.value);
      };
    
      const handleInputFocus = () => {
          setInputFocused(true);
      };
    
      const handleInputBlur = () => {
          setInputFocused(false);
      };
    
      return (
          <div className="campaign-name-input"  style={{alignSelf:'center', height:"50px", marginLeft:'0px', marginRight:'20px' }}>
              <input
                  type="password"
                  placeholder="campaign access key"
                  value={inputValue}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
              />
          </div>
      );
    }
    
  
    async function loginAdvertiser(campaignId, campaignAccessKey){
      const result = await globalApolloClient.mutate({
          mutation: LOGIN_ADVERTISER_CAMPAIGN_METRICS,
          variables: { campaignId, campaignAccessKey },
          fetchPolicy:"network-only"
      });
  
      if(result.data.loginCampaignMetricsViewPage.status == 'SUCCESS'){
        console.log('result.data.loginCampaignMetricsViewPage.response.campaignMetrics', result.data.loginCampaignMetricsViewPage.response.advertiser)
        localStorage.setItem('campaignId', campaignId);
        localStorage.setItem('campaignAccessKey', campaignAccessKey);
        localStorage.setItem('refresh', result.data.loginCampaignMetricsViewPage.response.advertiser.refresh);
        localStorage.setItem('token', result.data.loginCampaignMetricsViewPage.response.advertiser.token);
        setScreen('MAIN');
      }

      if(result.data.loginCampaignMetricsViewPage.status == 'ERROR'){
      setLoginError(true);
      setTimeout(()=>{setLoginError(false)},2500)
      }
  }
  
    return(
      <div style={{display:'flex', width:"100%", height:"360px", alignSelf:"center",justifyContent:'center', flexDirection:"column"}}>
        <div style={{display:'flex', position:'relative', width:'275px', height:'70px', alignSelf:'center',}}>
          <span style={{display:'flex', position:'absolute',alignSelf:'center', fontFamily:'Black Ops One', fontWeight:'regular', fontSize:'70px', color:'rgba(218, 238, 1,1)', zIndex:2, left:0, bottom:0}}>spaces</span>
          <span style={{display:'flex', position:'absolute',alignSelf:'center', fontFamily:'Black Ops One', fontWeight:'regular', fontSize:'70px', color:'rgba(82, 154, 89, 0.6)', zIndex:1, left:4,bottom:0}}>spaces</span>
        </div>
        <CampaignIdInput/>
        <CampaignViewAccesskeyInput/>
        <button style={{display:'flex', flexDirection:'row', width:'350px', height:'50px', justifyContent:'center', backgroundColor:"#1d1d1d", alignSelf:'center', borderRadius:'16px', cursor:'pointer', borderColor:"transparent", marginTop:'20px'}}
            onClick={async ()=>{
              await loginAdvertiser(campaignId.current, campaignAccessKey.current);
            }}>
                <span style={{display:'flex',fontSize:'22px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#F6F0ED', alignSelf:"center"}}>view campaign metrics</span>
        </button>
        {(loginError)?
        <div style={{display:'flex', flexDirection:'row', width:'280px', height:'35px', justifyContent:'center', backgroundColor:"#1d1d1d", alignSelf:'center', borderRadius:'16px', cursor:'pointer', borderColor:"transparent", marginTop:'20px'}}>
                <span style={{display:'flex',fontSize:'18px', fontFamily:'Comfortaa', fontWeight:'bold', color:'#F6F0ED', alignSelf:"center"}}>invalid crendentials</span>
        </div>
        :null}
      </div>
    );
  
  }

  return (
    <div className="App">
    {(screen == 'LOGIN')?
    <LoginScreen/>:
    (screen == 'MAIN')?
    <>
    <NavBar/>
    <MainScreen/>
    </>:null}
    </div>
  );
}

export default App;
